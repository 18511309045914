// extracted by mini-css-extract-plugin
export var ammoCaseStudyVideo = "bB_l9";
export var caseContactFormSection = "bB_k4";
export var caseSolutionRing = "bB_mb";
export var caseStudyBackground__bgColor = "bB_kV";
export var caseStudyBackground__lineColor = "bB_kW";
export var caseStudyCta__bgColor = "bB_l3";
export var caseStudyHead = "bB_k6";
export var caseStudyHead__imageWrapper = "bB_kT";
export var caseStudyProjectsSection = "bB_k5";
export var caseStudyQuote__dark = "bB_l6";
export var caseStudyQuote__light = "bB_l7";
export var caseStudySolution__ringOne = "bB_l8";
export var caseStudySolution__ringTwo = "bB_k0";
export var caseStudyTechSection = "bB_lb";
export var caseStudyVideo__ring = "bB_k8";
export var caseStudy__bgDark = "bB_kS";
export var caseStudy__bgLight = "bB_kR";